<template>
  <v-main :class="desktop ? 'tway-background-curves-svg' : 'tway-background-curves-svg-mobile-nologo'">
    <v-container v-if="loading" class="mt-15">
      <v-row justify="center">
        <v-col cols="12" align="center">
          <v-skeleton-loader width="700px" v-bind="attrs" type="article,list-item-three-line"></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" align="center">
          <v-skeleton-loader
            width="700px"
            v-bind="attrs"
            type="article, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line,actions"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>
    <v-row v-if="mobile" justify="center">
      <v-col cols="12">
        <v-img class="my-5 mb-10" :src="require('@/assets/img/byline.svg')" max-width="250px" />
      </v-col>
    </v-row>
    <v-row justify="center" class="mt-15">
      <v-col cols="12" align="center">
        <invitedRegistrationWelcome v-if="!loading" :context-data="contextData" :loading="loading" />
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" align="center">
        <surveyRegistration
          v-if="contextData.type === 'SURVEY' && !loading"
          :context-data="contextData"
          :loading="loading"
          :form-autocomplete-data="formAutocompleteData"
          :hash="hash"
        />
        <userRegistration
          v-if="contextData.type === 'USER' && !loading"
          :context-data="contextData"
          :loading="loading"
          :form-autocomplete-data="formAutocompleteData"
          :hash="hash"
        />
      </v-col>
    </v-row>
  </v-main>
</template>
<script>
import companyService from "@/services/companyService";
import CountryCodes from "@/utils/CountryCodes";
import GeoSectors from "@/utils/GeoSectors";
import surveyRegistration from "@/components/invitations/surveyInvitedRegistration";
import userRegistration from "@/components/invitations/userInvitedRegistration";
import invitedRegistrationWelcome from "@/components/invitations/invitedRegistrationWelcome";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    surveyRegistration,
    userRegistration,
    invitedRegistrationWelcome,
  },
  data() {
    return {
      contextData: {},
      loading: true,
      formAutocompleteData: {
        availableCountries: CountryCodes,
        availableBusinessArea: [],
        availableRegion: [],
        availableAgeRange: [],
        availableWorkplaceSeniority: [],
        availableManagements: [],
        availableGeoSectors: GeoSectors,
      },
      hash: "",
      attrs: {
        class: "mb-6",
        elevation: 2,
      },
      surveyRegistrationData: {},
    };
  },
  created() {
    if (this.$recaptchaInstance) {
      this.$recaptchaInstance.showBadge();
    }
    localStorage.removeItem("accessToken");
    this.hash = this.$route.params.hash;
  },
  async mounted() {
    Promise.all([
      this.fillWorkAreaAutocomplete(),
      this.fillAgeRangeAutocomplete(),
      this.fillManagementsAutocomplete(),
      this.contextDataFiller(this.hash),
    ]).then(() => {
      this.loading = false;
    });
  },
  beforeRouteLeave(to, from, next) {
    this.$recaptchaInstance.hideBadge();
    next();
  },
  methods: {
    ...mapActions("InvitationsStore", ["setSurveyInvitedContextData"]),
    async contextDataFiller(hash) {
      await this.$recaptchaLoaded();
      const token = await this.$recaptcha("registration");
      await this.setSurveyInvitedContextData({ token, hash }).then(() => {
        if (this.getSurveyInvitedContextData) {
          this.contextData = this.getSurveyInvitedContextData;
        }
      });
    },
    async fillWorkAreaAutocomplete() {
      await this.$recaptchaLoaded();
      const token = await this.$recaptcha("registration");
      const data = await companyService.getCompanyBusinessArea(token);
      this.formAutocompleteData.availableBusinessArea = data;
    },
    async fillManagementsAutocomplete() {
      await this.$recaptchaLoaded();
      const token = await this.$recaptcha("registration");
      const data = await companyService.getCompanyManagements(token);
      this.formAutocompleteData.availableManagements = data;
    },
    async fillRegionAutocomplete(countryId) {
      await this.$recaptchaLoaded();
      const token = await this.$recaptcha("registration");
      const data = await companyService.getCompanyRegion(countryId, token);
      this.formAutocompleteData.availableRegion = data;
    },
    async fillAgeRangeAutocomplete() {
      await this.$recaptchaLoaded();
      const token = await this.$recaptcha("registration");
      const data = await companyService.getCompanyAgeRange(token);
      this.formAutocompleteData.availableAgeRange = data;
    },
  },
  computed: {
    ...mapGetters("InvitationsStore", ["getSurveyInvitedContextData"]),
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
  },
};
</script>
<style lang="scss" scoped>
.register-column {
  span {
    font-size: 15px;
  }
}
.registration-card {
  max-width: 1100px;
}
.registration-container {
  display: flex;
  justify-content: center;
}
.divider {
  width: 100% !important;
}
.back {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-decoration-line: underline;
}
</style>
