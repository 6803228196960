<template>
  <v-card outlined :max-width="mobile ? '330px' : '700px'" width="100%" class="pa-10">
    <template>
      <v-form>
        <v-row justify="center" class="w-100 mb-4">
          <span v-if="desktop" style="font-size: 40px">Registro de Invitado</span>
          <span v-if="mobile" style="font-size: 24px">Registro de Invitado</span>
        </v-row>

        <span class="d-flex justify-start">Datos Personales </span>

        <div class="mb-1 mt-4">
          <v-row>
            <v-col>
              <v-text-field
                v-model="firstName"
                label="Nombre"
                name="name"
                type="text"
                :rules="[required('Nombre')]"
                required
                dense
                outlined
                color="#7319D5"
              />
            </v-col>
            <v-col>
              <v-text-field
                v-model="lastName"
                label="Apellido"
                name="lastName"
                type="text"
                :rules="[required('Apellido')]"
                required
                dense
                outlined
                color="#7319D5"
              />
            </v-col>
          </v-row>
        </div>
        <span class="d-flex justify-start">Información Empresa </span>
        <div class="mt-4">
          <v-row>
            <v-col cols="12"
              ><v-autocomplete
                v-model="countryCode"
                label="País"
                name="countryCode"
                :items="formAutocompleteData.availableCountries"
                hide-no-data
                hide-selected
                item-text="name"
                item-value="alpha2"
                required
                dense
                outlined
                color="#7319D5"
            /></v-col>
          </v-row>
        </div>

        <div class="mb-1" v-if="contextData.surveyIndex === 'IMTD'">
          <v-row>
            <v-col cols="6">
              <v-autocomplete
                v-model="metadata.management"
                label="Gerencia"
                name="management"
                :items="formAutocompleteData.availableManagements"
                hide-no-data
                hide-selected
                item-text="name"
                item-value="code"
                :rules="[required('Gerencia')]"
                required
                dense
                outlined
                color="#7319D5"
              />
            </v-col>
            <v-col cols="6" v-if="formAutocompleteData.availableBusinessArea.length != 0">
              <v-autocomplete
                v-model="metadata.businessArea"
                label="¿En qué área trabajas?"
                name="businessArea"
                :items="formAutocompleteData.availableBusinessArea"
                hide-no-data
                hide-selected
                item-text="name"
                item-value="code"
                :rules="[required('¿En qué área trabajas?')]"
                required
                dense
                outlined
                color="#7319D5"
              />
            </v-col>
          </v-row>
        </div>
        <div class="mb-1" v-else>
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                v-model="metadata.geoSector"
                label="Sector geografico"
                name="geoSector"
                :items="formAutocompleteData.availableGeoSectors"
                hide-no-data
                hide-selected
                item-text="name"
                item-value="code"
                :rules="[required('zona geografica')]"
                required
                dense
                outlined
                color="#7319D5"
              />
            </v-col>
          </v-row>
        </div>

        <v-row class="ma-0 pa-0 mb-4 register-column" justify="center">
          <v-btn
            color="tway-violet"
            class="white--text"
            height="36px"
            width="50%"
            rounded
            @click="registerUser"
            id="register-continue"
          >
            Continuar
          </v-btn>
        </v-row>
      </v-form>
    </template>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import ValidatorsService from "@/services/validatorsService";
import companyService from "@/services/companyService";

export default {
  name: "SurveyInvitedRegistration",
  props: {
    contextData: {
      type: Object,
      default: () => {},
    },
    formAutocompleteData: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: true,
    },
    hash: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      valid: false,
      firstName: "",
      lastName: "",
      countryCode: "",
      businessParams: {},
      metadata: {
        management: {},
        regionCode: {},
        businessArea: {},
        geoSector: {},
      },
      availableRegion: [],
    };
  },
  methods: {
    ...mapActions("InvitationsStore", ["setSurveyInvitedRegistration"]),
    async fillRegionAutocomplete(countryId) {
      await this.$recaptchaLoaded();
      const token = await this.$recaptcha("registration");
      const data = await companyService.getCompanyRegion(countryId, token);
      this.availableRegion = data;
    },
    validatePassword: () => ValidatorsService.validatePassword(),
    required: (propertyType) => ValidatorsService.required(propertyType),
    async registerUser() {
      if (this.contextData.type == null) return;
      await this.$recaptchaLoaded();
      const token = await this.$recaptcha("surveyRegistration");
      const hash = encodeURIComponent(this.hash);
      let data = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.contextData.email,
        clientType: this.contextData.type,
        countryCode: this.countryCode,
        businessParams: this.businessParams,
        token: token,
        hash: hash,
        metadata: this.metadata,
      };
      this.setSurveyInvitedRegistration(data);
      this.$router.push({
        name: "dtiFormInvited",
      });
    },
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
  },
  watch: {
    "metadata.management"() {
      let data = this.formAutocompleteData.availableManagements.find(
        (element) => element.code == this.metadata.management
      );
      this.availableBusinessAreas = data.businessAreas;
    },
  },
};
</script>

<style></style>
