<template>
  <div>
    <dtiEnding v-if="!loading && contextData.response == 400" />
    <v-card
      outlined
      :max-width="mobile ? '330px' : '700px'"
      width="100%"
      :class="mobile ? '' : 'mt-10'"
      v-if="!loading && contextData.response != 400"
    >
      <template>
        <v-row class="ma-0 pa-0">
          <v-col cols="12">
            <div class="mb-4 text-center">
              <strong>
                <span
                  style="font-style: normal; font-weight: normal; font-size: 35px; line-height: 50px; color: #333333"
                >
                  ¡Te damos la bienvenida!
                </span>
              </strong>
            </div>
          </v-col>

          <v-col cols="12">
            <div class="mb-4 text-start">
              <strong>
                <span class="text-h6" v-if="contextData.type === 'SURVEY' && !loading"
                  >{{ contextData.adminName }}, de {{ contextData.companyName }}, te ha invitado a responder la Encuesta
                  de Transformación Digital Tway. ¡Regístrate y comienza la encuesta!</span
                >
                <span class="text-h6" v-if="contextData.type === 'USER' && !loading">
                  {{ contextData.adminName }}, te ha invitado a contribuir a la gestión de la Transformación Digital de
                  la empresa {{ contextData.companyName }} en Tway. Completa el registro para acceder a la plataforma.
                </span>
              </strong>
            </div>
          </v-col>
        </v-row>
      </template>
    </v-card>
  </div>
</template>

<script>
import dtiEnding from "@/components/invitations/dtiEndingCard";
export default {
  name: "InvitedRegistrationWelcome",
  components: {
    dtiEnding,
  },
  props: {
    contextData: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
  },
};
</script>

<style></style>
